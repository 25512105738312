var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Main d'œuvres")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un main d'œuvre")]),_c('h4',[_vm._v(" Si vous souhaitez ajouter une main d'œuvre, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682597471_AjouterUne main d'œuvre.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier une main d'œuvre")]),_c('h4',[_vm._v(" Si vous souhaitez modifier une main d'œuvre déjà existante, suivez les étapes ci-dessous : ")]),_vm._m(1),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer une main d'œuvre")]),_c('h4',[_vm._v(" Pour supprimer une main d'oeuvre, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('p',[_vm._v(" Attention : la suppression d'un forfait est irréversible. Si vous êtes sûr de vouloir supprimer le forfait, veuillez cliquer sur \"\"Supprimer\"\" pour confirmer. ")]),_c('h3',{attrs:{"id":"recherche"}},[_vm._v("Rechercher ou filtrer les main d'œuvre")]),_c('h4',[_vm._v("Recherche main d'œuvre :")]),_vm._m(3),_c('h4',[_vm._v("Filtrage main d'œuvre :")]),_vm._m(4),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec une main d'œuvre, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de gestion des mains d'œuvre.")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau\".")]),_c('li',[_vm._v(" Remplissez le formulaire d'ajout (* il y a des champs obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\". Votre main d'œuvre est maintenant ajoutée. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page \"Gestion des mains d'œuvre\".")]),_c('li',[_vm._v(" recherchez la main d'œuvre que vous souhaitez modifier en utilisant les filtres de recherche si nécessaire. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" correspondant à la main d'œuvre que vous souhaitez modifier. ")]),_c('li',[_vm._v(" Modifiez les informations de la main d'œuvre dans le formulaire qui apparaît. (* il y a des champs obligatoires) ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer les modifications apportées à la main d'œuvre. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des mains d'œuvre.")]),_c('li',[_vm._v(" Recherchez la main d'œuvre que vous souhaitez supprimer à l'aide de la barre de recherche et des filtres disponibles. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Supprimer la main d'œuvre\".")]),_c('li',[_vm._v(" Confirmez la suppression en cliquant sur \"Supprimer\" dans la fenêtre pop-up de confirmation. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de la main d'œuvre.")]),_c('li',[_vm._v(" Cliquez sur la barre de recherche et entrez le nom ou une partie du nom de la main d'œuvre que vous cherchez. ")]),_c('li',[_vm._v("Les résultats correspondants à votre recherche seront affichés.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de la main d'œuvre.")]),_c('li',[_vm._v(" Utilisez les filtres disponibles pour affiner votre recherche soit afficher la liste des mains d'œuvres publique ou de masquer. ")])])
}]

export { render, staticRenderFns }